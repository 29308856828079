const config = {
  ENV: 'staging',
  SVC_GCP: 'https://api-staging.negobot.co',
  API_KEY: 'bhzziazcrdbjtvouaeme',
  CHAT_IFRAME: 'https://staging.buyer.iframe.negobot.co',
  ORG_INFO: {
    angliss: {
      orgId: 'angliss',
      apiKey: 'bhzziazcrdbjtvouaeme',
    },
    'angliss-mul': {
      orgId: 'angliss-mul',
      apiKey: 'fpfantounebfkmyuiywf',
    },
    'angliss-bas': {
      orgId: 'angliss-bas',
      apiKey: 'jzhkwxfdjyqvmpqtfjud',
    },
  },
};

export default config;
